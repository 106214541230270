const storeManageChilds = [
  // 门店管理
  {
    path: "madministra",
    name: "Madministra",
    component: () => import("@/views/storeManage/madministra.vue"),
    children: [
      {
        path: "administra",
        name: "Administra",
        component: () => import("@/views/storeManage/children/administra.vue"),
      },
    ],
  },
  {
    path: "mclassification",
    name: "Mclassification",
    component: () => import("@/views/storeManage/mclassification.vue"),
    children: [
      {
        path: "classification",
        name: "Classification",
        component: () => import("@/views/storeManage/children/classification.vue"),
      },
    ],
  },
  // 就餐记录
  {
    path: "dinings",
    name: "Dinings",
    component: () => import("@/views/storeManage/dinings.vue"),
    children: [
      {
        path: "diningRecords",
        name: "DiningRecords",
        component: () => import("@/views/storeManage/children/diningRecords.vue"),
      },
    ],
  },
  // 会员管理
  {
    path: "doorManage",
    name: "DoorManage",
    component: () => import("@/views/storeManage/doorManage.vue"),
    children: [
      {
        path: "doorMember",
        name: "DoorMember",
        component: () => import("@/views/storeManage/children/doorMember.vue"),
      }
    ]
  },
];
export default storeManageChilds;
